// 报告
import service from '@/utils/request'
// 查看报告列表
export function getReportList(params) {
  return service({
    url: '/result/list',
    method: 'get',
    params
  });
}
// 查看答题记录
export function answerList(params) {
    return service({
      url: '/result/answer',
      method: 'get',
      params
    });
}
// 获取测评报告
export function getReport(params) {
    return service({
      url: '/result/report',
      method: 'get',
      params
    });
}